@import "variables";

:root {
  /* fonts */
  --font-montserrat: Montserrat;
  --font-gotham: Gotham;

  /* font sizes */
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-xs: 12px;
  --font-size-lg: 18px;
  --font-size-sm: 14px;
  --font-size-mini-8: 14.8px;

  /* Colors */
  --color-whitesmoke-100: #f9f9f9;
  --color-whitesmoke-200: #402626;
  --color-whitesmoke-300: #eee;
  --color-whitesmoke-400: #ddd;
  --title-color: #211d4e;
  --color-white: #fff;
  --mj-light-green: #3adebb;
  --mj-deep-green: #219b8e;
  --color-darkgray-100: #999;
  --color-turquoise: #3adebb;
  --color-lightslategray: #918fa7;
  --label-color: #5a567b;
  --color-lightgray: #f9f9f9;
  --color-red: #de4e3a;
  --color-grey: #d3d3d3;

  /* Border radiuses */
  --br-3xs: 10px;
  --br-10xs: 3px;
  --br-8xs: 5px;

  /* Screen Size */
  --xs-screen: 0px;
  --sm-screen: 600px;
  --md-screen: 900px;
  --lg-screen: 1200px;
  --xl-screen: 1536px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif !important;
}

// html {
//     font-size: 100%; /* 1rem = 16px */
// }

body {
  font-size: 1rem; /* 16px */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  background-color: var(--color-lightgray);
}

.tableContainer::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  border-radius: 10px;
  background-color: #ebebeb; /* or add it to the track */
}

.tableContainer::-webkit-scrollbar-thumb {
  background: #aaa;
  border-radius: 10px;
}

.paddingContainer {
  padding-left: 25px;
  padding-right: 25px;
}

::-webkit-scrollbar {
  padding: 10px;
  width: 5px; /* Width of the scrollbar */
  height: 12px; /* Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ededed; /* Background of the track */
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #c6c6c6; /* Color of the scrollbar thumb */
  border-radius: 15px; /* Rounded corners for the thumb */
}